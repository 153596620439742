import {mapActions, mapGetters} from "vuex";

export default {
  name: "club-professional",
  computed:{
    ...mapGetters({
      club: 'pages/page',
    })
  },
  created() {
    this.fetchClubPage('professional-club')
  },
  methods: {
    ...mapActions({
      fetchClubPage: 'pages/GET_PAGE',
    })
  }
}